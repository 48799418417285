import React from 'react';
import styled from 'styled-components/macro';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import {
  FlexColumnContainer,
  FlexRowContainer,
  ThemedButton,
  FormTextInput,
  FormTextAreaInput,
} from '../components';

import { config } from '../utils';

import contactUsHeader from '../assets/images/contact-us-header.png';
import mailThemedIcon from '../assets/icons/mail-themed.svg';
import mobileIcon from '../assets/icons/mobile.svg';

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(150vh - 260px);
  position: relative;
`;

const FormContainer = styled(FlexColumnContainer.withComponent('div'))`
  padding: 35px 70px;
  position: absolute;
  width: 1164px;
  min-height: 532px;
  top: 135px;
  left: calc(50vw - 582px);
  background-color: #fff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  h3 {
    width: 100%;
    font-weight: 500;
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 55px;
  }

  ${ThemedButton} {
    width: 265px;
    height: 57px;
    margin: 0 auto;
  }

  & > ${FlexRowContainer} {
    width: 100%;
    margin-bottom: 55px;

    & > ${FlexColumnContainer}, & > ${FlexRowContainer} {
      width: 50%;

      label {
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
        color: #414141;
      }

      input {
        width: 410px;
        background: #f8f8f8;
        border: 1px solid #d7d7d7;
        border-radius: 10px;
        height: 50px;
        padding: 10px 20px;
      }

      div {
        margin-bottom: 20px;
      }

      textarea {
        width: 480px;
        background: #f8f8f8;
        border: 1px solid #d7d7d7;
        border-radius: 10px;
        padding: 10px 20px;
        min-height: 200px;
        resize: none;
        font-family: nunito;
      }
    }
  }
`;

const Header = styled.img`
  width: 100%;
  height: 360px;
  object-fit: cover;
`;

const ContactInfo = styled.div`
  width: 1000px;
  margin: 400px auto 300px auto;

  h3 {
    font-weight: 600;
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 55px;
  }

  & > ${FlexRowContainer} {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;

    & > p {
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      margin-left: 55px;
    }

    &:nth-of-type(2) {
      p {
        margin-left: 60px;
      }
    }
  }
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'name is too short')
    .max(255, 'name is too long')
    .required(),
  email: Yup.string().email().required(),
  message: Yup.string().min(1).max(9999).required(),
});

export const ContactUsContainer = () => {
  return (
    <Wrapper>
      <Header src={contactUsHeader} alt="" />
      <Formik
        initialValues={{
          name: '',
          email: '',
          message: '',
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={async (value, { resetForm }) => {
          try {
            const response = await fetch(`${config.apiBaseUrl}/contact-us`, {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
              },
              body: JSON.stringify(value),
            }).then((res) => res.json());
            if (response) {
              alert('Your message has been successfully submitted!');
              resetForm();
            }
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {() => (
          <Form>
            <FormContainer>
              <h3>Get in touch with us</h3>
              <FlexRowContainer>
                <FlexColumnContainer>
                  <Field
                    type="text"
                    name="name"
                    label="Your name"
                    placeholder="Your name"
                    component={FormTextInput}
                  />
                  <Field
                    type="email"
                    name="email"
                    label="Your email"
                    placeholder="Your email"
                    component={FormTextInput}
                  />
                </FlexColumnContainer>
                <FlexRowContainer>
                  <Field
                    name="message"
                    label="Your message"
                    placeholder="Your message"
                    component={FormTextAreaInput}
                  />
                </FlexRowContainer>
              </FlexRowContainer>
              <ThemedButton type="submit">Send Message</ThemedButton>
            </FormContainer>
          </Form>
        )}
      </Formik>
      <ContactInfo>
        <h3>Contact Us</h3>
        <FlexRowContainer>
          <img src={mailThemedIcon} alt="" />
          <p>qatarairways@finkraft.ai</p>
        </FlexRowContainer>
        <FlexRowContainer>
          <img src={mobileIcon} alt="" />
          <p>(91) 09876 54321</p>
        </FlexRowContainer>
      </ContactInfo>
    </Wrapper>
  );
};
