import React from 'react';
import styled from 'styled-components/macro';

import {
  FlexContainer,
  FlexColumnContainer,
  FlexRowContainer,
  BusinessTypeCard,
} from '../components';

import corporateThumbnail from '../assets/images/corporate-thumbnail.png';
import cargoThumbnail from '../assets/images/cargo-thumbnail.png';
import otaThumbnail from '../assets/images/ota-thumbnail.png';
import tmcThumbnail from '../assets/images/tmc-thumbnail.png';
import cross from '../assets/icons/cross.svg';
import poweredByFinkraft from '../assets/images/powered-by-finkraft-dark-text.svg';

const Container = styled(FlexContainer.withComponent('div'))`
  width: 100%;
  min-height: 100vh;

  background-color: #f2f2f2;
`;

const MainSection = styled(FlexColumnContainer)`
  position: relative;
  width: 761px;
  height: 506px;
  background: #ffffff;
  border: 0.570465px solid #f4f4f4;
  box-shadow: 0px 2.28186px 5.13418px rgba(173, 173, 173, 0.300126);
  border-radius: 14.2616px;

  justify-content: center;
  align-items: center;
  margin: 40px auto 0 auto;
`;

const Close = styled.img`
  position: absolute;

  top: 20px;
  right: 20px;
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const PoweredByFinkraft = styled.img`
  width: 70px;
  height: 35px;

  position: absolute;
  bottom: -80px;
  left: 10px;
`;

const SectionTitle = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: #000000;
`;

const SectionDescription = styled.p`
  width: 375px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  margin-top: 18px;
`;

const CardsContainer = styled(FlexRowContainer)`
  margin-top: 40px;
  margin-bottom: 65px;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
`;

const ProceedButton = styled.button`
  width: 252px;
  height: 45px;
  background: #f5a623;
  border-radius: 59px;
  font-size: 14.8321px;
  line-height: 29px;

  color: #ffffff;
  cursor: pointer;
  outline: none;
  border: none;
`;

const urlsMap = {
  corporate: 'https://qatar-finkraft.typeform.com/to/eiNjeK',
  cargo: 'https://qatar-finkraft.typeform.com/to/SQBANhoq',
  'online travel agent': 'https://qatar-finkraft.typeform.com/to/rPaaykP2',
  'travel agent': 'https://qatar-finkraft.typeform.com/to/Sb6o6CW8',
};

export default (props) => {
  const [selectedCard, setSelectedCard] = React.useState('');

  const onCardClick = React.useCallback(
    (cardTitle) => {
      setSelectedCard(cardTitle);
    },
    [setSelectedCard]
  );

  const onClose = () => {
    props.history.push('/');
  };

  const onProceed = () => {
    if (selectedCard && urlsMap[selectedCard]) {
      window.location.replace(urlsMap[selectedCard]);
    }
  };

  return (
    <Container>
      <MainSection>
        <Close src={cross} onClick={onClose} />
        <PoweredByFinkraft src={poweredByFinkraft} />
        <SectionTitle>What type of a business are you?</SectionTitle>
        <SectionDescription>
          Using our custom engine you'll be able to upload ticket data and
          download Tax Invoices
        </SectionDescription>
        <BusinessTypeCards
          selectedCard={selectedCard}
          onCardClick={onCardClick}
        />
        <ProceedButton onClick={onProceed}>Proceed</ProceedButton>
      </MainSection>
    </Container>
  );
};

const businessTypes = [
  {
    businessType: 'Corporate',
    businessTypeDesc:
      'Download invoices and upload ticketing data for your business travel',
    thumbnail: corporateThumbnail,
  },
  {
    businessType: 'Cargo',
    businessTypeDesc: 'Download invoices for the cargo shipments',
    thumbnail: cargoThumbnail,
  },
  {
    businessType: 'Online Travel Agent',
    businessTypeDesc: 'Upload/Download TCS data from the portal',
    thumbnail: otaThumbnail,
  },
  {
    businessType: 'Travel Agent',
    businessTypeDesc:
      'Travel Agents can upload ticketing details for their customers with correct GST details to create correct Tax invoices',
    thumbnail: tmcThumbnail,
  },
];

export const BusinessTypeCards = ({
  selectedCard,
  showThumbnails = true,
  onCardClick,
}) => {
  return (
    <CardsContainer>
      {businessTypes.map((obj, i) => (
        <BusinessTypeCard
          key={i}
          isSelected={selectedCard === obj.businessType.toLowerCase()}
          thumbnail={obj.thumbnail}
          showThumbnail={showThumbnails}
          cardTitle={obj.businessType}
          cardDesc={obj.businessTypeDesc}
          onCardClick={onCardClick}
        />
      ))}
    </CardsContainer>
  );
};
