import { combineReducers } from 'redux';

import loaderReducer from './loaderReducer';
import authReducer from './authReducer';
import boxStorageReducer from './boxReducer';

export default combineReducers({
  showLoader: loaderReducer,
  auth: authReducer,
  boxCreds: boxStorageReducer,
});
