import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;

  & img {
    margin: 0 15px;
  }

  & input {
    min-width: 180px;
    border: none;
    outline: none;
    opacity: 1;
  }
`;

const Divider = styled.hr`
  margin: 10px 0;
  width: 100%;
  opacity: 0.75;
  border-top: ${(props) =>
    props.isValid ? '0.5px solid #979797' : '0.5px solid #D90000;'};
`;

const ErrorMsg = styled.p`
  font-size: 12px;
  line-height: 16px;

  color: #d90000;
`;

export const LoginInput = ({
  icon,
  isIconVisible,
  type,
  value,
  onChange,
  onBlur = () => {},
  onKeyPress = () => {},
  placeholder,
  errorMsg = '',
}) => (
  <Wrapper>
    <div>
      <img
        src={icon}
        alt=""
        style={{ visibility: isIconVisible ? 'visible' : 'hidden' }}
      />
      <input
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
      />
    </div>
    <Divider isValid={!Boolean(errorMsg)} />
    {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
  </Wrapper>
);
