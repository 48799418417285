import React from 'react';
import { useLocation } from 'react-router-dom';

export const Intercom = () => {
  const location = useLocation();

  // Boot up intercom when the component mounts.
  React.useEffect(() => {
    window.Intercom('boot', {
      app_id: 'tdyve18j',
    });
  }, []);

  // Run the update action on every route change to get latest messages.
  React.useEffect(() => {
    window.Intercom('update', {
      last_request_at: parseInt(new Date().getTime() / 1000),
    });
  }, [location]);

  return null;
};
