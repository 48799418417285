import React from 'react';
import styled from 'styled-components/macro';
import Modal from 'react-modal';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { ThemedButton } from './Themed';
import {
  FormTextInput,
  BusinessTypeSelectionComponent,
} from './FormComponents';
import {
  FormContainer,
  FormRow,
  NextButton,
} from './Registration/CorporateDetailsForm';
import { config } from '../utils';

import checkMarkInsideCircle from '../assets/icons/checkmark-inside-circle.svg';
import cross from '../assets/icons/cross.svg';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
  },
  content: {
    position: 'absolute',
    top: 'calc(50vh - 350px)',
    right: 'calc(50vw - 390px)',
    left: 'auto',
    bottom: 'auto',
    width: '780px',
    height: '700px',
    backgroundColor: '#fff',
    borderRadius: '13px',
    zIndex: 150,
  },
};

const SignupModalContainer = styled.div`
  margin: 0 auto;

  & ${FormRow} {
    justify-content: flex-start;
    align-items: flex-start;

    & > div {
      margin-right: 30px;
    }

    & button:disabled {
      cursor: not-allowed;
    }
  }

  & #business-type-row {
    & > div {
      width: 740px;

      & > section {
        margin-top: 10px;
        margin-bottom: 0;
      }
    }
  }
`;

const Close = styled.img`
  position: absolute;

  top: 20px;
  right: 20px;
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const FormButton = styled(ThemedButton)`
  width: 160px;
  height: 30px;
  margin-top: 25px;
`;

const initialValues = {
  fullName: '',
  emailId: '',
  emailOtp: '',
  mobileNumber: '',
  smsOtp: '',
  businessType: '',
};

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(5, 'Full name cannot be less than 5 characters.')
    .max(255, 'Full name cannot be more than 255 characters')
    .required('Full Name is required'),
  emailId: Yup.string()
    .email('Email address is invalid')
    .required('Email address is invalid'),
  mobileNumber: Yup.string()
    .length(10, 'Mobile Number should be 10 digits in length')
    .required('Mobile Number is required'),
  businessType: Yup.string()
    .oneOf(['corporate', 'travel agent', 'online travel agent', 'cargo'])
    .required('Business Type is required'),
});

export const SignupModal = ({
  showModal,
  email,
  onRequestClose,
  isSubmitting,
  onSubmit,
}) => {
  const [emailOtpSent, setEmailOtpSent] = React.useState(false);
  const [smsOtpSent, setSmsOtpSent] = React.useState(false);
  const [emailVerified, setEmailVerified] = React.useState(false);
  const [mobileNumberVerified, setMobileNumberVerified] = React.useState(false);

  const onEmailOtpClick = async (emailId) => {
    try {
      if (emailId) {
        const response = await fetch(
          `${config.apiBaseUrl}/user-info-validation/start`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({
              channel: 'email',
              emailId,
            }),
          }
        );
        const result = await response.json();
        console.log(result);
        if (result.message) {
          setEmailOtpSent(true);
        }
      } else {
        alert('Enter your email address!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onEmailOtpVerifyClick = async (emailId, emailOtp) => {
    try {
      const response = await fetch(
        `${config.apiBaseUrl}/user-info-validation/verify`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            channel: 'email',
            emailId,
            code: emailOtp,
          }),
        }
      );
      const result = await response.json();
      if (result.isVerified) {
        setEmailVerified(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSmsOtpClick = async (mobileNumber, setFieldError) => {
    try {
      if (mobileNumber && mobileNumber.length === 10) {
        const response = await fetch(
          `${config.apiBaseUrl}/user-info-validation/start`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({
              channel: 'sms',
              mobileNumber,
            }),
          }
        );
        const statusCode = response.status;
        const result = await response.json();
        if (statusCode === 200) {
          setSmsOtpSent(true);
        } else if (statusCode === 409) {
          setFieldError('mobileNumber', result.message);
        } else {
          throw new Error(result.message);
        }
      } else {
        alert('Enter you mobile number!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSmsOtpVerifyClick = async (mobileNumber, smsOtp) => {
    try {
      const response = await fetch(
        `${config.apiBaseUrl}/user-info-validation/verify`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            channel: 'sms',
            mobileNumber,
            code: smsOtp,
          }),
        }
      );
      const result = await response.json();
      if (result.isVerified) {
        setMobileNumberVerified(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={showModal}
      shouldCloseOnEsc={false}
      style={modalStyles}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onRequestClose}
    >
      <SignupModalContainer>
        <Close src={cross} onClick={onRequestClose} />
        <h1>Sign Up</h1>
        {emailOtpSent && (
          <p style={{ color: 'green' }}>OTP Sent on your email address</p>
        )}
        {smsOtpSent && (
          <p style={{ color: 'green' }}>OTP Sent on your Mobile Number</p>
        )}
        <Formik
          initialValues={{ ...initialValues, emailId: email }}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={false}
          validateOnMount={false}
          onSubmit={onSubmit}
        >
          {({ values, setFieldError }) => (
            <Form>
              <FormContainer>
                <FormRow>
                  <Field
                    name="fullName"
                    label="Full Name:"
                    component={FormTextInput}
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="emailId"
                    label="Email Address:"
                    component={FormTextInput}
                    readOnly
                  />
                  {emailVerified ? (
                    <div
                      style={{
                        marginTop: '25px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={checkMarkInsideCircle}
                        alt=""
                        width="30"
                        height="30"
                      />
                      &nbsp;&nbsp;Verified
                    </div>
                  ) : (
                    <>
                      {emailOtpSent && (
                        <Field
                          name="emailOtp"
                          label="Email Verification Code:"
                          component={FormTextInput}
                        />
                      )}
                      {emailOtpSent ? (
                        <FormButton
                          type="button"
                          onClick={() =>
                            onEmailOtpVerifyClick(
                              values.emailId,
                              values.emailOtp
                            )
                          }
                          disabled={
                            !values.emailOtp || !values.emailOtp.length === 6
                          }
                        >
                          Verify Code
                        </FormButton>
                      ) : (
                        <FormButton
                          type="button"
                          onClick={() => onEmailOtpClick(values.emailId)}
                        >
                          Get Verification Code
                        </FormButton>
                      )}
                    </>
                  )}
                </FormRow>
                <FormRow>
                  <Field
                    name="mobileNumber"
                    label="Mobile Number:"
                    component={FormTextInput}
                  />
                  {mobileNumberVerified ? (
                    <div
                      style={{
                        marginTop: '25px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={checkMarkInsideCircle}
                        alt=""
                        width="30"
                        height="30"
                      />
                      &nbsp;&nbsp;Verified
                    </div>
                  ) : (
                    <>
                      {smsOtpSent && (
                        <Field
                          name="smsOtp"
                          label="Mobile Verification Code:"
                          component={FormTextInput}
                        />
                      )}
                      {smsOtpSent ? (
                        <FormButton
                          type="button"
                          onClick={() =>
                            onSmsOtpVerifyClick(
                              values.mobileNumber,
                              values.smsOtp
                            )
                          }
                          disabled={
                            !values.smsOtp || !values.smsOtp.length === 6
                          }
                        >
                          Verify Code
                        </FormButton>
                      ) : (
                        <FormButton
                          type="button"
                          onClick={() =>
                            onSmsOtpClick(values.mobileNumber, setFieldError)
                          }
                        >
                          Get Verification Code
                        </FormButton>
                      )}
                    </>
                  )}
                </FormRow>
                <FormRow id="business-type-row">
                  <Field
                    name="businessType"
                    component={BusinessTypeSelectionComponent}
                  />
                </FormRow>
                <FormRow style={{ justifyContent: 'center' }}>
                  <NextButton
                    type="submit"
                    disabled={!emailVerified || !mobileNumberVerified}
                    style={{
                      backgroundColor: isSubmitting ? 'green' : '#f5a623',
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    {isSubmitting ? 'Signing Up...' : 'Sign Up'}
                  </NextButton>
                </FormRow>
              </FormContainer>
            </Form>
          )}
        </Formik>
      </SignupModalContainer>
    </Modal>
  );
};
