import React from 'react';
import styled from 'styled-components/macro';

import { FlexColumnContainer } from '../Themed';

const Card = styled(FlexColumnContainer.withComponent('div'))`
  width: 160px;
  height: ${(props) => (props.showThumbnail ? '250px' : '150px')};
  background-color: #fff;
  border: ${(props) =>
    props.isSelected ? '1px solid #df5590' : '0.5px solid #f4f4f4'};
  box-shadow: 0px 2.3px 5px rgba(173, 173, 173, 0.3);
  border-radius: 5.7px;

  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: border 0.1s ease-in;
`;

const Thumbnail = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-top: 20px;
`;

const CardTitle = styled.h1`
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
`;

const CardDescription = styled.p`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 130px;
`;

export const BusinessTypeCard = React.memo(
  ({
    isSelected,
    thumbnail,
    showThumbnail,
    cardTitle,
    cardDesc,
    onCardClick,
  }) => {
    return (
      <Card
        showThumbnail={showThumbnail}
        isSelected={isSelected}
        onClick={() => onCardClick(cardTitle.toLowerCase())}
      >
        {showThumbnail && <Thumbnail src={thumbnail} alt="card asset" />}
        <CardTitle>{cardTitle}</CardTitle>
        <CardDescription>{cardDesc}</CardDescription>
      </Card>
    );
  }
);
