import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';

import { FlexContainer } from './Themed';

import poweredByFinkraft from '../assets/images/powered-by-finkraft-light-text.svg';
import mailIcon from '../assets/icons/mail.svg';
import twitterIcon from '../assets/icons/twitter.svg';

const FooterContainer = styled(FlexContainer.withComponent('footer'))`
  height: 160px;
  color: white;
  background-color: black;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 283px;

  & div {
    flex: 0 1 30%;
    display: flex;
    align-items: center;

    &:first-of-type {
      justify-content: flex-start;
    }

    &:last-of-type {
      justify-content: flex-end;
    }

    &:first-of-type > a > img {
      width: 120px;
    }
  }
`;

const FooterLink = styled.a`
  text-decoration: none;
  color: #fff;
`;

const LinkIcon = styled(FlexContainer.withComponent('a'))`
  text-decoration: none;
  width: 30px;
  height: 30px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  margin: 0 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:last-of-type {
    margin: 0;
  }

  & img {
    overflow: hidden;
  }
`;

export const Footer = () => {
  const emailId = useSelector((state) => state.auth.emailId);

  return (
    <FooterContainer>
      <div>
        <a
          href="https://finkraft.ai"
          target="_blank"
          rel="noopener noreferrer"
          onClick={ReactGA.event({
            category: 'Finkraft Portal',
            action: 'incoming-finkraft-portal',
            label: emailId,
          })}
        >
          <img src={poweredByFinkraft} alt="powered by Finkraft" />
        </a>
      </div>
      <div>
        <FooterLink href="mailto:qatarairways@finkraft.ai">
          qatarairways@finkraft.ai
        </FooterLink>
        <LinkIcon href="mailto:qatarairways@finkraft.ai">
          <img src={mailIcon} alt="" />
        </LinkIcon>
        <LinkIcon href="https://twitter.com/qatarairways" target="_blank">
          <img src={twitterIcon} alt="twitter" />
        </LinkIcon>
      </div>
    </FooterContainer>
  );
};
