import React from 'react';
import styled from 'styled-components/macro';
import Modal from 'react-modal';
import ReactGA from 'react-ga';

import { FlexColumnContainer } from './Themed';

import invoiceDialogCheckmark from '../assets/icons/invoice-dialog-checkmark.svg';
import cross from '../assets/icons/cross.svg';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(196, 196, 196, 0.5)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
  },
  content: {
    position: 'absolute',
    top: 'calc(50vh - 234px)',
    right: 'calc(50vw - 311px)',
    left: 'auto',
    bottom: 'auto',
    width: '622px',
    height: '468px',
    backgroundColor: '#fff',
    boxShadow: '0px 6px 16px rgba(153, 155, 168, 0.1)',
    borderRadius: '4px',
    zIndex: 150,
  },
};

const Container = styled(FlexColumnContainer.withComponent('div'))`
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: flex-start;
`;

const Close = styled.img`
  position: absolute;

  top: 20px;
  right: 20px;
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const DownloadedFile = styled.div`
  width: 566px;
  margin-top: 34px;

  & > p {
    font-size: 14px;
    line-height: 17px;
    color: #8181a5;
    font-weight: 600;
  }
`;

const Checkmark = styled.img`
  width: 112px;
  height: 112px;
  margin-top: 60px;
`;

const SuccessMsg = styled.h3`
  width: 340px;
  text-align: center;
  color: #1c1d21;
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 24px;
  margin-top: 40px;
`;

const USPText = styled.p`
  width: 440px;
  color: #8181a5;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  margin-top: 20px;

  & > a {
    font-weight: 600;
    text-decoration: none;
    color: dodgerblue;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const InvoicesDownloadedDialog = ({
  emailId,
  showModal,
  onRequestClose,
  fileName = '',
}) => {
  return (
    <Modal
      isOpen={showModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={modalStyles}
      onRequestClose={onRequestClose}
      contentLabel="Invoices Downloaded Dialog"
    >
      <Container>
        <Close src={cross} onClick={onRequestClose} />
        <DownloadedFile>
          <p>Downloaded File:</p>
          <p>{fileName}</p>
        </DownloadedFile>
        <Checkmark src={invoiceDialogCheckmark} alt="" />
        <SuccessMsg>You have successfully downloaded the invoice</SuccessMsg>
        <USPText>
          Downloading the invoice is the first step towards compliant GST
          filing. <br />
          To learn how to easily claim more than 99% of the eligible GST, <br />
          visit{' '}
          <a
            href="https://knowledge.finkraft.ai"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              ReactGA.event({
                category: 'Finkraft Portal',
                action: 'incoming-finkraft-portal',
                label: emailId,
              });
            }}
          >
            knowledge.finkraft.ai
          </a>
        </USPText>
      </Container>
    </Modal>
  );
};
