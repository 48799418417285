import React from 'react';
import styled from 'styled-components/macro';

import { FlexRowContainer } from '../Themed';

const HomeSectionContainer = styled(FlexRowContainer)`
  height: 600px;
  background-color: ${(props) => props.backgroundColor || '#fff'};
  justify-content: space-evenly;
  align-items: center;
  z-index: -100;

  & > div:first-of-type {
    order: ${(props) => (props.rtl ? 2 : 1)};
    text-align: ${(props) => (props.rtl ? 'right' : 'left')};
  }

  & div:last-of-type {
    order: ${(props) => (props.rtl ? 1 : 2)};
  }
`;

export const Seperator = styled.div`
  border: 9px solid #7f2049;
  border-radius: 10px;
  width: 130px;
  position: relative;
  display: block;

  margin-top: 53px;
  margin-bottom: 45px;

  margin-left: ${(props) => (props.rtl ? '350px' : 0)};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: -9px;
    right: -70px;
    width: 40px;
    border: 9px solid #7f2049;
    border-radius: 10px;
  }
`;

const SectionHeading = styled.h1`
  font-weight: 500;
  font-size: 40px;
  line-height: 55px;
  width: 541px;
`;

const SectionText = styled.p`
  width: 541px;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
`;

const SectionImageContainer = styled.div`
  position: relative;
  overflow: visible;
  z-index: 30;
  margin-top: 100px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;

    top: -50px;
    left: -40px;
    background-color: #e2e2e299;
    border-radius: 35px;

    z-index: -1;
  }

  & img {
    z-index: 100;
  }
`;

export const Section = React.memo(
  ({
    sectionIndex,
    currentStep,
    headerText,
    text,
    image,
    backgroundColor,
    rtl = false,
  }) => {
    const sectionRef = React.useRef(null);

    React.useEffect(() => {
      if (sectionIndex === currentStep) {
        if (sectionRef.current) {
          setTimeout(() => {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
          }, 500);
        }
      }
    }, [sectionIndex, currentStep]);

    return (
      <HomeSectionContainer
        id={`section${sectionIndex}`}
        ref={sectionRef}
        backgroundColor={backgroundColor}
        rtl={rtl}
      >
        <div>
          <SectionHeading>{headerText}</SectionHeading>
          <Seperator rtl={rtl} />
          <SectionText>{text}</SectionText>
        </div>
        <div>
          <SectionImageContainer>
            <img src={image} alt="illustration" />
          </SectionImageContainer>
        </div>
      </HomeSectionContainer>
    );
  }
);
