import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { ContentExplorer } from 'box-ui-elements';
import { IntlProvider } from 'react-intl';
import ReactGA from 'react-ga';

import { fetchBoxCreds, showLoader, hideLoader } from '../actions';

import { FlexRowContainer, InvoicesDownloadedDialog } from '../components';

import poweredByFinkraftDarkText from '../assets/images/powered-by-finkraft-dark-text.svg';

const WrapperContainer = styled.div`
  width: 100%;
  padding: 40px 20px;

  justify-content: center;
  align-items: center;

  & > ${FlexRowContainer} {
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    & h2 {
      margin-right: 20px;
    }
  }

  h1 {
    width: 100%;
    min-height: calc(100vh - 350px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select {
    width: 300px;
  }

  .bce-content {
    flex: 1 0 850px;
  }
`;

export const InvoicesContainer = (props) => {
  const [currentBoxFolderId, setCurrentBoxFolderId] = React.useState('');
  const [selectionOptions, setSelectionOptions] = React.useState([]);
  const [
    showInvoicesDownloadedDialog,
    setShowInvoicesDownloadedDialog,
  ] = React.useState(false);
  const [downloadedFileName, setDownloadedFileName] = React.useState('');

  const boxCreds = useSelector((state) => state.boxCreds);
  const { isUserLoggedIn, emailId } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isUserLoggedIn) {
      const { boxFolderDetails, token, error } = boxCreds;
      if (!boxFolderDetails.length || !token) dispatch(fetchBoxCreds());
      if (boxFolderDetails.length) {
        ReactGA.event({
          category: 'Invoices',
          action: 'invoice-accessed',
        });
        setCurrentBoxFolderId(boxFolderDetails[0].boxFolderId);
        setSelectionOptions(
          boxFolderDetails.map((obj) => ({
            value: obj.boxFolderId,
            label: obj.boxFolderName,
          }))
        );
      }
      if (error) props.history.replace(`/registration?email=${emailId}`);
    }
  }, [boxCreds, dispatch, isUserLoggedIn, props.history, emailId]);

  const onInvoicesDownload = (files) => {
    if (files.length) {
      setDownloadedFileName(files[0].name);
    } else {
      setDownloadedFileName(files.name);
    }
    const invoicesDownloaded = localStorage.getItem('invoicesDownloaded');

    let updatedInvoicesDownloaded;

    if (invoicesDownloaded) {
      updatedInvoicesDownloaded = Number(invoicesDownloaded) + 1;
    } else {
      updatedInvoicesDownloaded = 1;
    }
    localStorage.setItem('invoicesDownloaded', updatedInvoicesDownloaded);

    if (
      updatedInvoicesDownloaded === 1 ||
      updatedInvoicesDownloaded % 5 === 0
    ) {
      setShowInvoicesDownloadedDialog(true);
    } else {
      setShowInvoicesDownloadedDialog(false);
    }
  };

  return (
    <WrapperContainer>
      {boxCreds.token && currentBoxFolderId ? (
        <>
          <FlexRowContainer>
            <h2>View Invoices for PAN:</h2>
            <Select
              defaultValue={selectionOptions[0]}
              value={selectionOptions.filter(
                (option) => option.value === currentBoxFolderId
              )}
              options={selectionOptions}
              className="select"
              isDisabled={false}
              isLoading={false}
              isClearable={false}
              isRtl={false}
              isSearchable={true}
              name="box-folder-ids"
              onChange={(option) => {
                if (option && option.value !== currentBoxFolderId) {
                  dispatch(showLoader());
                  setCurrentBoxFolderId(''); // Set to empty string to unmount the Box component.
                  setTimeout(() => {
                    setCurrentBoxFolderId(option.value); // When the value is set, it remounts the component. Acts as a page refresh.
                    dispatch(hideLoader());
                  }, 1000);
                }
              }}
            />
          </FlexRowContainer>
          <IntlProvider locale="en">
            <ContentExplorer
              token={boxCreds.token}
              rootFolderId={currentBoxFolderId}
              language="en-US"
              logoUrl={poweredByFinkraftDarkText}
              canDelete={false}
              canRename={false}
              canPreview={true}
              canUpload={false}
              canCreateNewFolder={false}
              canShare={true}
              canSetShareAccess={true}
              onDownload={onInvoicesDownload}
            />
          </IntlProvider>
        </>
      ) : (
        <h1>
          We currently do not have any invoices to show you. <br /> If you think
          there has been some mistake please contact support.
        </h1>
      )}
      {showInvoicesDownloadedDialog && (
        <InvoicesDownloadedDialog
          emailId={emailId}
          showModal={showInvoicesDownloadedDialog}
          onRequestClose={() => setShowInvoicesDownloadedDialog(false)}
          fileName={downloadedFileName}
        />
      )}
    </WrapperContainer>
  );
};
