import React from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed';

export const TypeformEmbedComponent = ({ url, showForm, onSubmit }) => {
  const [showTypeform, setShowTypefrom] = React.useState(false);
  const typeformEmbedRef = React.useRef(null);

  React.useEffect(() => {
    if (showForm) {
      setShowTypefrom(false);
      setTimeout(() => setShowTypefrom(showForm), 100);
      setTimeout(() => openTypeform(), 300);
    } else {
      setShowTypefrom(false);
    }
  }, [showForm]);

  const openTypeform = () => {
    if (typeformEmbedRef.current) {
      typeformEmbedRef.current.typeform.open();
    }
  };

  return (
    <>
      {showTypeform && (
        <ReactTypeformEmbed
          popup
          url={url}
          hideHeaders
          hideFooter
          ref={typeformEmbedRef}
          style={{
            width: 0,
            height: 0,
          }}
          autoOpen={false}
          autoClose={2}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
