import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import ReactGA from 'react-ga';

import { showLoginModal } from '../../actions';
import { config } from '../../utils';

import { FlexColumnContainer, FlexRowContainer } from '../Themed';
import { SignupModal } from '../SignupModal';
import { unofficialEmailProvidersPattern } from '../../utils/regexPatterns';

import heroBackground1 from '../../assets/images/hero-background-1.png';

const HeroSectionContainer = styled.section`
  width: 100%;
  height: 600px;
`;

const HeroContent = styled(FlexColumnContainer)`
  height: 480px;
  position: relative;

  justify-content: center;
  align-items: flex-start;

  & > div {
    width: 100%;
    padding: 0 130px;
    margin: 0 auto;
    z-index: 4;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    object-fit: cover;
  }

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    color: #fff;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    margin-bottom: 76px;
  }

  input {
    width: 380px;
    height: 60px;
    border-radius: 35px;
    padding-left: 35px;
    margin-right: 20px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: none;

    &::placeholder {
      color: #000;
      font-size: 16px;
      font-weight: 500;
    }
  }

  a,
  button {
    display: block;
    background: #f5a623;
    border-radius: 35px;
    width: 162px;
    height: 60px;
    color: #fff;
    font-size: 16px;
    line-height: 28px;
    outline: none;
    border: none;
    cursor: pointer;

    /* added only for the anchor tag temporarily */
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StepDetailsContainer = styled(FlexRowContainer)`
  height: 120px;
  background-color: #434344;
  color: white;
  justify-content: space-evenly;
  align-items: center;

  & > ${FlexRowContainer} {
    flex: 0 1 25%;
    justify-content: center;
    align-items: center;
  }
`;

const MaskingBackground = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.322039) 0%,
    rgba(0, 0, 0, 0.598166) 47.42%,
    #000000 100%
  );
  z-index: 4;
`;

const Step = styled(FlexRowContainer)`
  cursor: pointer;
`;

const StepNumber = styled.div`
  margin-right: 20px;
  font-weight: 500;
  font-size: 64.4px;
  line-height: 88px;
`;

const StepTitle = styled.h4`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #fff;
`;

const StepText = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  width: 231px;
  color: #fff;
`;

const businessTypesValuesMapping = {
  corporate: 'corporate',
  cargo: 'cargo',
  'online travel agent': 'ota',
  'travel agent': 'tmc',
};

export const HeroSection = React.memo(({ onStepClick }) => {
  const [email, setEmail] = React.useState('');
  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const [isCheckingUserExists, setIsCheckingUserExists] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);

  const history = useHistory();
  const dispatch = useDispatch();

  const onEmailInput = React.useCallback(
    (e) => {
      const value = e.target.value;
      setEmail(value.toLowerCase());

      const schema = Yup.string().email().required();

      schema.isValid(value).then((valid) => {
        if (valid) {
          if (!unofficialEmailProvidersPattern.test(value.split('@')[1])) {
            setIsEmailValid(true);
          } else {
            setIsEmailValid(false);
            setErrorMessage('Enter your official email address');
          }
        } else {
          setIsEmailValid(false);
          setErrorMessage('Enter a valid email address');
        }
      });
    },
    [setEmail, setIsEmailValid]
  );

  const onRegisterClick = React.useCallback(async () => {
    try {
      if (!isUserLoggedIn) {
        setShowModal(false);
        setIsCheckingUserExists(true);
        const { userExists } = await fetch(
          `${config.apiBaseUrl}/user/validate`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({
              emailId: email,
            }),
          }
        ).then((res) => res.json());

        setIsCheckingUserExists(false);

        if (userExists) {
          history.replace(`/?email=${email}`);
          dispatch(showLoginModal());
          setEmail('');
        } else {
          ReactGA.event({
            category: 'Registration',
            action: 'Sign-up_initiated',
          });
          setShowModal(true);
          setTimeout(() => {
            setEmail('');
          }, 1000);
        }
      } else {
        setEmail('');
        alert('You are already logged in!');
      }
    } catch (error) {
      console.log(error);
      setIsCheckingUserExists(false);
    }
  }, [isUserLoggedIn, email, history, dispatch]);

  const handleSignup = async (values) => {
    try {
      const newValuesObj = { ...values };
      newValuesObj.mobileNumber = `+91${values.mobileNumber}`;
      newValuesObj.businessType =
        businessTypesValuesMapping[values.businessType];
      setIsSubmitting(true);
      const response = await fetch(`${config.apiBaseUrl}/user/new`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(newValuesObj),
      });
      const result = await response.json();
      if (result) {
        ReactGA.event({
          category: 'Registration',
          action: 'Sign-up_success',
        });
        setShowModal(false);
        history.replace(`/?email=${values.emailId}`);
        dispatch(showLoginModal());
      }
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <HeroSectionContainer>
        <HeroContent>
          <MaskingBackground />
          <img src={heroBackground1} alt="hero-background-1" />
          <div>
            <h1>Your one stop solution to download tax invoices</h1>
            <p>for Corporates, TMCs, OTAs and Cargo</p>
            <FlexRowContainer>
              <input
                disabled={isUserLoggedIn}
                placeholder="Email address"
                value={email}
                onChange={onEmailInput}
                style={{
                  border: isEmailValid ? '2px solid green' : ' 2px solid red',
                }}
              />
              {isEmailValid ? (
                <button
                  disabled={isUserLoggedIn}
                  onClick={onRegisterClick}
                  style={{
                    backgroundColor: isCheckingUserExists ? 'green' : '#f5a623',
                    cursor:
                      isUserLoggedIn || !isEmailValid
                        ? 'not-allowed'
                        : 'pointer',
                  }}
                >
                  {isCheckingUserExists ? 'Validating...' : 'Sign Up'}
                </button>
              ) : (
                <button
                  disabled={isUserLoggedIn}
                  style={{
                    cursor:
                      isUserLoggedIn || !isEmailValid
                        ? 'not-allowed'
                        : 'pointer',
                  }}
                >
                  Sign Up
                </button>
              )}
            </FlexRowContainer>
            {!isEmailValid && errorMessage ? (
              <p style={{ color: '#fff', margin: '10px 30px' }}>
                {errorMessage}
              </p>
            ) : (
              <p style={{ visibility: 'hidden', margin: '10px 30px' }}>
                placeholder
              </p>
            )}
          </div>
        </HeroContent>
        <StepDetailsContainer>
          <Step onClick={() => onStepClick(1)}>
            <StepNumber>01</StepNumber>
            <div>
              <StepTitle>Download your GST Invoice</StepTitle>
              <StepText>Download Tax Invoices for travel and cargo</StepText>
            </div>
          </Step>
          <Step onClick={() => onStepClick(2)}>
            <StepNumber>02</StepNumber>
            <div>
              <StepTitle>Upload Travel details</StepTitle>
              <StepText>
                Ensure correct GST details get captured & no loss of GST Credit
              </StepText>
            </div>
          </Step>
          <Step onClick={() => onStepClick(3)}>
            <StepNumber>03</StepNumber>
            <div>
              <StepTitle>Download a compilation</StepTitle>
              <StepText>Download various reports for reconciliation</StepText>
            </div>
          </Step>
        </StepDetailsContainer>
      </HeroSectionContainer>
      <SignupModal
        showModal={showModal}
        email={email}
        isSubmitting={isSubmitting}
        onRequestClose={() => setShowModal(false)}
        onSubmit={handleSignup}
      />
    </>
  );
});
