import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showLoginModal, logoutUser } from '../actions';

import { FlexRowContainer } from './Themed';
import { Auth } from '../services';

import qatarLogo from '../assets/logos/qatar-logo.png';
// import chatIcon from '../assets/icons/chat.svg';
import userIcon from '../assets/icons/user.svg';
// import mobileIcon from '../assets/icons/mobile.svg';
import docIcon from '../assets/icons/doc.svg';

const auth = new Auth();

const NavContainer = styled(FlexRowContainer.withComponent('nav'))`
  height: 100px;
  background-color: #ffffff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  padding: 0 122px;
  justify-content: space-between;
  align-items: center;
`;

const NavListContainer = styled(FlexRowContainer.withComponent('ul'))`
  width: auto;
  list-style: none;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const NavListItem = styled(FlexRowContainer.withComponent('li'))`
  width: auto;
  margin-left: 65px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #7f2049;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 3px solid #fff;
  transition: border-bottom linear 0.1s;

  &:hover {
    border-bottom: 3px solid #7f2049;
  }
`;

const NavIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 13px;
  object-fit: contain;
`;

export const Navbar = () => {
  const { isUserLoggedIn, emailId } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const onLoginClick = () => {
    dispatch(showLoginModal());
  };

  const onLogoutClick = React.useCallback(() => {
    try {
      dispatch(logoutUser());
      auth.logOut();
    } catch (error) {}
  }, [dispatch]);

  return (
    <NavContainer>
      <div>
        <Link to={{ pathname: '/' }}>
          <img src={qatarLogo} alt="qatar airways" />
        </Link>
      </div>
      <NavListContainer>
        {/* <Link to="/contact-us">
          <NavListItem>
            <NavIcon src={mobileIcon} alt="help/faq" />
            Contact Us 24/7
          </NavListItem>
        </Link>
        <Link
          to={{
            pathname: '/',
            hash: '#faqs',
          }}
        >
          <NavListItem>
            <NavIcon src={chatIcon} alt="help/faq" />
            Help/FAQ's
          </NavListItem>
        </Link> */}
        {isUserLoggedIn && (
          <>
            <Link to={`/registration?email=${emailId}`}>
              <NavListItem>
                <NavIcon src={userIcon} alt="invoices" />
                Registration
              </NavListItem>
            </Link>
            <Link to="/invoices">
              <NavListItem>
                <NavIcon src={docIcon} alt="invoices" />
                Invoices
              </NavListItem>
            </Link>
          </>
        )}
        {isUserLoggedIn ? (
          <NavListItem onClick={onLogoutClick}>
            <NavIcon src={userIcon} alt="user" />
            Logout
          </NavListItem>
        ) : (
          <NavListItem onClick={onLoginClick}>
            <NavIcon src={userIcon} alt="user" />
            Login
          </NavListItem>
        )}
      </NavListContainer>
    </NavContainer>
  );
};
