import React from 'react';
import { useDispatch } from 'react-redux';

import { setLoggedInUser, hideLoader, showLoader } from '../actions';

import { Auth } from '../services';

const auth = new Auth();

export const CallbackContainer = (props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async function () {
      try {
        window.Intercom('shutdown');
        dispatch(showLoader());
        const result = await auth.getAuthResult();
        localStorage.setItem('accessToken', result.accessToken);
        localStorage.setItem(
          'expiresAt',
          new Date().getTime() + Number(result.expiresIn) * 1000
        );
        const user = await auth.getUserInfo({
          accessToken: result.accessToken,
        });

        localStorage.setItem('email', user.email);
        dispatch(
          setLoggedInUser({
            accessToken: result.accessToken,
            emailId: user.email,
            auth0Result: result,
            auth0User: user,
          })
        );
        window.Intercom('boot', {
          app_id: 'tdyve18j',
          email: user.email,
        });
        props.history.replace(`/registration?email=${user.email}`);
        dispatch(hideLoader());
      } catch (error) {
        window.Intercom('boot', {
          app_id: 'tdyve18j',
        });
        dispatch(hideLoader());
        props.history.replace('/');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};
