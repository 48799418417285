import * as Yup from 'yup';

import { unofficialEmailProvidersPattern } from './regexPatterns';

export const isEmailValid = async (string) => {
  try {
    const unofficialEmailProviders = (string) =>
      unofficialEmailProvidersPattern.test(string.split('@')[1]);
    const schema = Yup.string()
      .email('Enter a valid email ID')
      .required('Email Id is required')
      .test(
        'unofficial-email',
        'Enter an official email ID',
        (value) => !unofficialEmailProviders(value)
      );
    const result = await schema.validate(string);
    return { result, error: null };
  } catch (error) {
    return { result: null, error };
  }
};

export const isPhoneNumberValid = async (string) => {
  try {
    const isNumeric = (value) => /^[0-9]*$/.test(value);

    const schema = Yup.string()
      .test('phone-number', 'Invalid phone number', (value) => isNumeric(value))
      .ensure()
      .length(10, 'Phone number should be 10 digits.')
      .required('Phone number is required');
    const result = await schema.validate(string);
    return { result, error: null };
  } catch (error) {
    return { result: null, error };
  }
};
