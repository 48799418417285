import React from 'react';

import {
  HeroSection,
  Section,
  FaqSection,
  FaqModal,
  ProgressBar,
} from '../components';

import section1Image from '../assets/images/home-section-1.png';
import section2Image from '../assets/images/home-section-2.png';

import faqs from '../assets/documents/faqs.json';

export const LandingContainer = (props) => {
  const [displayFaqsModal, setDisplayFaqsModal] = React.useState(false);
  const [currentStep, setStep] = React.useState(0);

  const onStepClick = React.useCallback(
    (i) => {
      setStep(i);
    },
    [setStep]
  );

  const showFaqsModal = React.useCallback(() => {
    setDisplayFaqsModal(true);
  }, [setDisplayFaqsModal]);

  const hideFaqsModal = React.useCallback(() => {
    setDisplayFaqsModal(false);
  }, [setDisplayFaqsModal]);

  return (
    <>
      <HeroSection onStepClick={onStepClick} />
      <ProgressBar currentState={currentStep} totalStates={3} />
      <Section
        sectionIndex={1}
        currentStep={currentStep}
        headerText="We value your money"
        text="Download Airline Tax Invoices for the tickets booked & timely file your GST return."
        image={section1Image}
        backgroundColor="#f4f6f7"
      />
      <Section
        sectionIndex={2}
        currentStep={currentStep}
        headerText="Download all your GST invoices"
        text="Get all your GST invoices at a single place in a predefined folder structure."
        image={section2Image}
        backgroundColor="#fff"
        rtl={true}
      />
      <Section
        sectionIndex={3}
        currentStep={currentStep}
        headerText="We are here to help"
        text="Enter your email ID to view reconciled dashboards and reports for your transactions"
        image={section1Image}
        backgroundColor="#f4f6f7"
      />
      <FaqSection faqs={faqs} onViewAllClick={showFaqsModal} />
      <FaqModal
        faqs={faqs}
        isOpen={displayFaqsModal}
        onRequestClose={hideFaqsModal}
      />
    </>
  );
};
