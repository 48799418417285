import React from 'react';
import styled from 'styled-components';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';

import { FlexRowContainer, FlexColumnContainer, ThemedButton } from '../Themed';
import { FormTextInput } from '../FormComponents';

import plusInsideCircle from '../../assets/icons/plus-inside-circle.svg';

export const FormContainer = styled(FlexColumnContainer.withComponent('div'))`
  width: 740px;
  height: auto;
  min-height: 400px;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`;

export const FormRow = styled(FlexRowContainer.withComponent('div'))`
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 17px;
  position: relative;

  &:first-of-type {
    margin-top: 30px;
  }

  & > p {
    position: absolute;
    bottom: 7px;
    right: 40px;
    width: 20px;
    height: 20px;
    border: 2px solid red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    font-weight: bold;
    cursor: pointer;
  }

  & > h4 {
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    color: #068b89;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.075s ease-in-out;

    &:hover {
      border-bottom: 2px solid #068b89;
    }
  }
`;

export const NextButton = styled(ThemedButton)`
  width: 250px;
  height: 45px;
  margin-top: 50px;
  margin-bottom: 30px;
`;

const validationSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(5, 'Company Name cannot be less than 5 characters')
    .max(255, 'Company Name cannot be more than 255 characters')
    .required('Company name is required')
    .test(
      'is-unique',
      'Company name already exists',
      (value) =>
        new Promise((resolve, reject) => {
          setTimeout(() => resolve(false), 1000);
        })
    ),
  companyWebsite: Yup.string()
    .min(5, 'Company Website cannot be less than 5 characters')
    .max(255, 'Company Website cannot be more than 255 characters')
    .url('Company Website must be a valid URL')
    .required('Company website is required'),
  gstDetails: Yup.array().of(
    Yup.object()
      .shape({
        gstNumber: Yup.string()
          .length(12, 'GST Number should be 12 characters long')
          .required('GST Number is required'),
        entityName: Yup.string()
          .min(5, 'Entity Name cannot be less than 5 characters')
          .max(255, 'Entity Name cannot be more than 255 characters')
          .required('Entity name is required'),
      })
      .required()
  ),
});

export const CorporateDetailsForm = () => {
  return (
    <Formik
      initialValues={{
        companyName: '',
        companyWebsite: '',
        gstDetails: [
          {
            gstNumber: '',
            entityName: '',
          },
        ],
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={(value) => console.log(value)}
    >
      {({ values }) => (
        <Form>
          <FormContainer>
            <div style={{ width: '100%' }}>
              <FormRow>
                <Field
                  type="text"
                  name="companyName"
                  label="Company Name:"
                  component={FormTextInput}
                />
                <Field
                  type="text"
                  name="companyWebsite"
                  label="Company Website:"
                  component={FormTextInput}
                />
              </FormRow>
              <FieldArray name="gstDetails">
                {({ insert, remove }) => (
                  <>
                    {values.gstDetails.map((value, i) => (
                      <div style={{ width: '100%' }} key={i}>
                        <FormRow>
                          <Field
                            type="text"
                            name={`gstDetails.${i}.gstNumber`}
                            label="GST Number:"
                            component={FormTextInput}
                          />
                          <Field
                            type="text"
                            name={`gstDetails.${i}.entityName`}
                            label="Entity Name:"
                            component={FormTextInput}
                          />
                          {i !== 0 && (
                            <p style={{}} onClick={() => remove(i)}>
                              X
                            </p>
                          )}
                        </FormRow>
                        {values.gstDetails.length === i + 1 && (
                          <FormRow
                            style={{
                              justifyContent: 'flex-start',
                              marginLeft: '11.5%',
                            }}
                          >
                            <h4
                              onClick={() =>
                                insert(i, { gstNumber: '', entityName: '' })
                              }
                            >
                              <span>
                                <img src={plusInsideCircle} alt="" />
                              </span>{' '}
                              Add GST Details
                            </h4>
                          </FormRow>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>
            </div>
            <FormRow>
              <NextButton type="submit">Next</NextButton>
            </FormRow>
          </FormContainer>
        </Form>
      )}
    </Formik>
  );
};
