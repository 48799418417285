import { showLoader, hideLoader } from './loaderActions';
import { Auth } from '../services';

const auth = new Auth();

export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL';
export const SET_LOGGEDIN_USER = 'SET_LOGGEDIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const showLoginModal = () => ({
  type: SHOW_LOGIN_MODAL,
});

export const hideLoginModal = () => ({
  type: HIDE_LOGIN_MODAL,
});

export const setLoggedInUser = (payload) => ({
  type: SET_LOGGEDIN_USER,
  payload,
});

export const logoutUser = (payload) => ({
  type: LOGOUT_USER,
});

export const trySilentLogin = () => async (dispatch) => {
  window.Intercom('shutdown');
  if (auth.isTokenValid()) {
    dispatch(showLoader());
    const localAccessToken = localStorage.getItem('accessToken');
    const user = await auth.getUserInfo({
      accessToken: localAccessToken,
    });
    window.Intercom('boot', {
      app_id: 'tdyve18j',
      email: user.email,
    });
    dispatch(
      setLoggedInUser({
        didSilentLogin: true,
        accessToken: localAccessToken,
        emailId: user.email,
        auth0Result: {},
        auth0User: user,
      })
    );
    dispatch(hideLoader());
  } else {
    localStorage.clear();
    window.Intercom('boot', {
      app_id: 'tdyve18j',
    });
  }
};
