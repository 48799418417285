import axios from 'axios';
import { config } from '../utils';
import { showLoader, hideLoader } from '../actions';

export const SET_BOX_CREDS = 'SET_BOX_CREDS';
export const SET_BOX_ERROR = 'SET_BOX_ERROR';

export const setBoxCreds = (payload) => ({
  type: SET_BOX_CREDS,
  payload,
});

export const setBoxError = (payload) => ({
  type: SET_BOX_ERROR,
  payload,
});

export const fetchBoxCreds = () => async (dispatch, getState) => {
  dispatch(showLoader());
  const state = getState();
  try {
    const response = await axios.get(`${config.apiBaseUrl}/box/token`, {
      headers: {
        Authorization: `Bearer ${state.auth.accessToken}`,
      },
    });
    const boxCreds = response.data;
    dispatch(setBoxCreds(boxCreds));
    dispatch(hideLoader());
  } catch (error) {
    dispatch(setBoxError(error.message));
    dispatch(hideLoader());
  }
};
