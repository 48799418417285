import React from 'react';
import styled from 'styled-components/macro';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../actions';
import { camelCase, kebabCase } from 'lodash';
import ReactGA from 'react-ga';

import {
  FlexContainer,
  FlexColumnContainer,
  FlexRowContainer,
  FormTypeCard,
  DummyFormTypeCard,
  TypeformEmbedComponent,
} from '../components';
import { config } from '../utils';

import warning from '../assets/icons/warning.svg';
import plus from '../assets/icons/plus.svg';
import tickColored from '../assets/icons/tick-colored.svg';
import poweredByFinkraft from '../assets/images/powered-by-finkraft-dark-text.svg';

const Container = styled(FlexContainer.withComponent('div'))`
  width: 100%;
  min-height: calc(100vh - 260px);

  background-color: #f2f2f2;
`;

const MainSection = styled(FlexColumnContainer)`
  position: relative;
  min-width: 761px;
  max-width: 1161px;
  height: 506px;
  background: #ffffff;
  border: 0.570465px solid #f4f4f4;
  box-shadow: 0px 2.28186px 5.13418px rgba(173, 173, 173, 0.300126);
  border-radius: 14.2616px;

  justify-content: center;
  align-items: center;
  margin: 40px auto 150px auto;
`;

const PoweredByFinkraft = styled.img`
  width: 70px;
  height: 35px;

  position: absolute;
  bottom: -80px;
  left: 10px;
`;

const SectionTitle = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  color: #000000;
  width: 800px;
  text-align: center;
`;

const SectionMessage = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
  width: 800px;
  text-align: center;
`;

const CardsContainer = styled(FlexRowContainer)`
  margin-top: 40px;
  margin-bottom: 65px;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
`;

const ProceedButton = styled.button`
  width: 252px;
  height: 45px;
  background-color: ${(props) => (props.disabled ? '#f5a62399' : '#f5a623')};
  border-radius: 59px;
  font-size: 14.8321px;
  line-height: 29px;

  color: #ffffff;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  outline: none;
  border: none;
`;

// ! Replace # with ? for hidden fields for ReactTypeformEmbed component to pass the params properly
const getFormsMap = (emailId) => ({
  corporate: [
    {
      formName: 'Company Details',
      formEmbedLink: config.typeFormUrls.companyDetails(emailId),
    },
    {
      formName: 'TMC Details',
      formEmbedLink: config.typeFormUrls.tmcDetails(emailId),
    },
    {
      formName: 'Tax Contact',
      formEmbedLink: config.typeFormUrls.taxContact(emailId),
    },
    {
      formName: 'Travel Contact',
      formEmbedLink: config.typeFormUrls.travelContact(emailId),
    },
  ],
  cargo: [
    {
      formName: 'Company Details',
      formEmbedLink: config.typeFormUrls.companyDetails(emailId),
    },
    {
      formName: 'Tax Contact',
      formEmbedLink: config.typeFormUrls.taxContact(emailId),
    },
  ],
  ota: [
    {
      formName: 'Tax Contact',
      formEmbedLink: config.typeFormUrls.taxContact(emailId),
    },
  ],
  tmc: [
    {
      formName: 'Client Details',
      formEmbedLink: `https://qatar-finkraft.typeform.com/to/SRamWP5B?emailid=${emailId}&typeofform=clientDetails`,
    },
    {
      formName: 'Tax Contact',
      formEmbedLink: config.typeFormUrls.taxContact(emailId),
    },
  ],
});

export default (props) => {
  const [emailId, setEmailId] = React.useState('');
  const [businessType, setBusinessType] = React.useState('');
  const [selectedCard, setSelectedCard] = React.useState('');
  const [registrationCompleted, setRegistrationCompleted] = React.useState(
    false
  );
  const [showTypeform, setShowTypeform] = React.useState(false);
  const [userMetadata, setUserMetadata] = React.useState('');
  const [entityMetadata, setEntityMetadata] = React.useState('');
  const [showAddPanTypeform, setShowAddPanTypeform] = React.useState(false);
  const [showAddUsersTypeform, setShowAddUsersTypeform] = React.useState(false);

  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);

  const dispatch = useDispatch();

  const fetchUserData = async (emailId) => {
    try {
      const userData = await fetch(`${config.apiBaseUrl}/user`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          emailId,
        }),
      }).then((res) => res.json());
      return userData;
    } catch (error) {
      throw error;
    }
  };

  const fetchEntityData = async (emailId) => {
    try {
      const entityData = await fetch(`${config.apiBaseUrl}/user/getEntity`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          emailId,
        }),
      }).then((res) => res.json());
      return entityData;
    } catch (error) {
      throw error;
    }
  };

  React.useEffect(() => {
    (async function () {
      try {
        if (!isUserLoggedIn) props.history.replace('/');
        dispatch(showLoader());
        const { email } = queryString.parse(props.location.search);
        if (email) {
          setEmailId(email);
          const userData = await fetchUserData(email);
          const entityData = await fetchEntityData(email);
          if (
            !userData[0]?.user_metadata?.business_type &&
            !entityData?.businessType
          ) {
            throw new Error('missing details');
          }
          setBusinessType(
            userData[0].user_metadata.business_type.toLowerCase() ||
              entityData.businessType.toLowerCase()
          );
          setUserMetadata(userData[0].user_metadata);
          setEntityMetadata(entityData);
          setRegistrationCompleted(
            entityData && !Object.values(entityData.formCompletion).includes(0)
              ? true
              : false
          );
          dispatch(hideLoader());
        } else {
          dispatch(hideLoader());
          props.history.replace('/');
        }
      } catch (error) {
        console.log(error);
        dispatch(hideLoader());
        props.history.replace('/');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = async () => {
    try {
      const userData = await fetchUserData(emailId);
      const entityData = await fetchEntityData(emailId);
      if (
        !userData[0].user_metadata.business_type &&
        !entityData.businessType
      ) {
        throw new Error('missing details');
      }
      setBusinessType(
        userData[0].user_metadata.business_type.toLowerCase() ||
          entityData.businessType.toLowerCase()
      );
      setUserMetadata(userData[0].user_metadata);
      setEntityMetadata(entityData);
      setRegistrationCompleted(
        entityData && !Object.values(entityData?.formCompletion).includes(0)
          ? true
          : false
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onCardClick = React.useCallback(
    (cardTitle) => {
      setSelectedCard(cardTitle);
      // Set showTypeform as false to unmount the <ReactTypeformEmbed /> component
      setShowTypeform(false);
      // Remount the component so as to update the URL passed to it based on the selected card.
      setTimeout(() => setShowTypeform(true), 100);
    },
    [setSelectedCard, setShowTypeform]
  );

  const onProceed = () => {
    props.history.push('/invoices');
  };

  const onAddPanCardClick = () => {
    setSelectedCard('add pan');
    setShowAddPanTypeform(false);
    setTimeout(() => setShowAddPanTypeform(true), 50);
  };

  const onAddUserClick = () => {
    setSelectedCard('add users');
    setShowAddUsersTypeform(false);
    setTimeout(() => setShowAddUsersTypeform(true), 50);
  };

  const formsMap = getFormsMap(emailId);

  return (
    <>
      <Container>
        <MainSection>
          <PoweredByFinkraft src={poweredByFinkraft} />
          <SectionTitle>
            {registrationCompleted
              ? 'Thank you for completing the registration process'
              : 'Please fill out these forms to complete the registration process and access your invoices'}
          </SectionTitle>
          <CardsContainer>
            {businessType &&
              formsMap[businessType].map((obj, i) => (
                <FormTypeCard
                  key={i}
                  isSelected={selectedCard === obj.formName.toLowerCase()}
                  thumbnail={
                    entityMetadata?.formCompletion?.[camelCase(obj.formName)]
                      ? tickColored
                      : warning
                  }
                  cardTitle={obj.formName}
                  isCompleted={
                    entityMetadata?.formCompletion?.[camelCase(obj.formName)]
                      ? true
                      : false
                  }
                  onCardClick={onCardClick}
                  canAddMore={[
                    'tmc details',
                    'tax contact',
                    'travel contact',
                  ].includes(obj.formName.toLowerCase())}
                />
              ))}
            {businessType.toLowerCase() !== 'tmc' &&
              entityMetadata?.formCompletion?.companyDetails === 1 && (
                <>
                  <DummyFormTypeCard
                    isSelected={selectedCard === 'add users'}
                    thumbnail={plus}
                    cardTitle="Add Users"
                    onCardClick={onAddUserClick}
                  />
                  <DummyFormTypeCard
                    isSelected={selectedCard === 'add pan'}
                    thumbnail={plus}
                    cardTitle="Add PAN"
                    onCardClick={onAddPanCardClick}
                  />
                </>
              )}
          </CardsContainer>
          <ProceedButton
            style={{
              backgroundColor:
                registrationCompleted &&
                userMetadata.invoiceAccess &&
                entityMetadata.invoiceAccess
                  ? '#3BB54A'
                  : '',
            }}
            disabled={
              !registrationCompleted ||
              !userMetadata.invoiceAccess ||
              !entityMetadata.invoiceAccess
            }
            onClick={onProceed}
          >
            View Invoices
          </ProceedButton>
          <SectionMessage>
            {registrationCompleted &&
            userMetadata.invoiceAccess &&
            entityMetadata.invoiceAccess
              ? 'You can view your invoices now!'
              : `${
                  registrationCompleted
                    ? 'Please give us 24 hours to validate your information. Upon successful validation, we will inform you via email to access your invoices.'
                    : 'You dont have access yet'
                } `}
          </SectionMessage>
        </MainSection>
      </Container>
      {showTypeform && (
        <TypeformEmbedComponent
          url={
            formsMap[businessType].filter(
              (obj) => obj.formName.toLowerCase() === selectedCard
            )[0]?.formEmbedLink || ''
          }
          showForm={showTypeform}
          onSubmit={() => {
            ReactGA.event({
              category: 'Company and Contact Details',
              action: `${kebabCase(selectedCard)}-form_filled`,
            });
            setTimeout(onFormSubmit, 10000);
          }}
        />
      )}
      <TypeformEmbedComponent
        url={config.typeFormUrls.addNewUser(emailId)}
        showForm={showAddUsersTypeform}
        onSubmit={() => {
          ReactGA.event({
            category: 'Company and Contact Details',
            action: 'add-another-pan-form_filled',
          });
        }}
      />
      <TypeformEmbedComponent
        url={config.typeFormUrls.addNewPan(emailId)}
        showForm={showAddPanTypeform}
        onSubmit={() => {
          ReactGA.event({
            category: 'Company and Contact Details',
            action: 'add-another-pan-form_filled',
          });
        }}
      />
    </>
  );
};
