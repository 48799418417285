import React from 'react';
import styled from 'styled-components/macro';

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 10px;
  background-color: #979797;

  & > div {
    background-color: #df5590;
    height: 10px;
    width: ${(props) =>
      props.progressPercentage ? props.progressPercentage : 0};
    transition: width ease-in-out 0.2s;
  }
`;

export const ProgressBar = React.memo(({ currentState, totalStates }) => {
  const progressPercentage = `${Math.round(
    (currentState / totalStates) * 100
  )}%`;

  return (
    <ProgressBarContainer progressPercentage={progressPercentage}>
      <div></div>
    </ProgressBarContainer>
  );
});
