import { SET_BOX_CREDS, SET_BOX_ERROR } from '../actions';

const initialState = {
  boxFolderDetails: [],
  token: '',
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BOX_CREDS:
      return Object.assign({}, state, action.payload);

    case SET_BOX_ERROR:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
