import React from 'react';
import ReactGA from 'react-ga';

import { config } from '../utils';

ReactGA.initialize(config.googleAnalyticsTrackingId, {
  debug: false,
  titleCase: true,
  redactEmail: false,
});

const currentUrl = () => {
  return window.location.pathname + window.location.search;
};

export const GoogleAnalytics = (props) => {
  React.useEffect(() => {
    ReactGA.set({ page: currentUrl() });
    ReactGA.pageview(currentUrl());
  }, [props.location]);

  return null;
};
