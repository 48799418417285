import auth0 from 'auth0-js';

import { config } from '../utils';

const {
  auth0: {
    domain,
    clientId: clientID,
    audience,
    responseType,
    redirectUri,
    returnTo,
  },
} = config;

export class Auth {
  auth = new auth0.WebAuth({
    domain,
    clientID,
    redirectUri,
    audience,
    responseType,
    scope: 'openid profile email',
  });

  emailLoginStart = ({ email }) => {
    return new Promise((resolve, reject) => {
      this.auth.passwordlessStart(
        { connection: 'email', send: 'code', email },
        (err, result) => {
          if (err) reject(err);
          resolve(result);
        }
      );
    });
  };

  smsLoginStart = ({ phoneNumber }) => {
    return new Promise((resolve, reject) => {
      this.auth.passwordlessStart(
        { connection: 'sms', send: 'code', phoneNumber: `+91${phoneNumber}` },
        (err, result) => {
          if (err) reject(err);
          resolve(result);
        }
      );
    });
  };

  emailLoginVerify = ({ email, verificationCode }) => {
    return new Promise((resolve, reject) => {
      this.auth.passwordlessVerify(
        {
          connection: 'email',
          email,
          verificationCode,
        },
        (err, result) => {
          if (err) reject(err);
          resolve(result);
        }
      );
    });
  };

  smsLoginVerify = ({ phoneNumber, verificationCode }) => {
    return new Promise((resolve, reject) => {
      this.auth.passwordlessVerify(
        {
          connection: 'sms',
          phoneNumber: `+91${phoneNumber}`,
          verificationCode,
        },
        (err, result) => {
          if (err) reject(err);
          resolve(result);
        }
      );
    });
  };

  getAuthResult = () => {
    return new Promise((resolve, reject) => {
      this.auth.parseHash((err, authResult) => {
        if (err) reject(err);
        resolve(authResult);
      });
    });
  };

  getUserInfo = ({ accessToken }) => {
    return new Promise((resolve, reject) => {
      this.auth.client.userInfo(accessToken, (err, result) => {
        if (err) reject(err);
        resolve(result);
      });
    });
  };

  logOut = () => {
    localStorage.clear();
    window.Intercom('shutdown');

    this.auth.logout({
      returnTo,
      clientID,
    });
    window.Intercom('boot', {
      app_id: 'tdyve18j',
    });
  };

  isTokenValid = () => {
    return localStorage.getItem('accessToken') &&
      localStorage.getItem('expiresAt') > new Date().getTime()
      ? true
      : false;
  };
}
