import React from 'react';
import styled from 'styled-components/macro';
import Select from 'react-select';

import { FlexColumnContainer } from './Themed';
import { BusinessTypeCards } from '../containers/BusinessTypeContainer';

const FormInputWrapper = styled(FlexColumnContainer.withComponent('div'))`
  width: 245px;
  flex-wrap: nowrap;

  & > label {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 7px;
    font-weight: 600;

    color: #000000;
  }

  & > input {
    height: 30px;
    background: #ffffff;
    border: 1.11195px solid #e9edf3;
    border-radius: 3.33584px;
    padding: 0 5px;
  }

  & > textarea {
    height: 30px;
    background: #ffffff;
    border: 1.11195px solid #e9edf3;
    border-radius: 3.33584px;
  }
`;

const ErrorMsg = styled.p`
  font-size: 12px;
  line-height: 18px;

  color: #d90000;
  margin-top: 5px;
`;

export const FormTextInput = ({ field, form, ...props }) => {
  const meta = form.getFieldMeta(field.name);

  return (
    <FormInputWrapper>
      <label htmlFor={field.name}>{props.label}</label>
      <input
        {...field}
        {...props}
        style={{
          ...props.style,
          border: meta.touched && meta.error ? '1px solid #d90000' : '',
        }}
      />
      {meta.touched && meta.error && <ErrorMsg>{meta.error}</ErrorMsg>}
    </FormInputWrapper>
  );
};

export const FormTextAreaInput = ({ field, form, ...props }) => {
  const meta = form.getFieldMeta(field.name);

  return (
    <FormInputWrapper>
      <label htmlFor={field.name}>{props.label}</label>
      <textarea
        {...field}
        {...props}
        style={{
          ...props.style,
          border: meta.touched && meta.error ? '1px solid #d90000' : '',
        }}
      />
      {meta.touched && meta.error && <ErrorMsg>{meta.error}</ErrorMsg>}
    </FormInputWrapper>
  );
};

export const CustomSelectComponent = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const { options } = props;

  return (
    <FormInputWrapper>
      <label htmlFor={field.name}>{props.label}</label>
      <Select
        {...field}
        {...props}
        options={options}
        value={
          options ? options.find((option) => option.value === field.value) : ''
        }
        onChange={(option) => setFieldValue(field.name, option.value)}
      />
      {touched[field.name] && errors[field.name] && (
        <ErrorMsg>{errors[field.name]}</ErrorMsg>
      )}
    </FormInputWrapper>
  );
};

export const BusinessTypeSelectionComponent = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  return (
    <div style={{ marginRight: 0 }}>
      <FormInputWrapper>
        <label>Business Type:</label>
      </FormInputWrapper>
      <BusinessTypeCards
        selectedCard={field.value}
        showThumbnails={false}
        onCardClick={(cardTitle) => setFieldValue(field.name, cardTitle)}
      />
      {!!touched[field.name] && !!errors[field.name] && (
        <ErrorMsg>{errors[field.name]}</ErrorMsg>
      )}
    </div>
  );
};
