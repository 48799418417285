import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import {
  FlexContainer,
  FlexRowContainer,
  FlexColumnContainer,
  Seperator,
} from '../components';

import upload from '../assets/icons/dashboard-upload.svg';
import dashboard from '../assets/icons/dashboard-dashboard.svg';
import graph from '../assets/icons/dashboard-graph.svg';
import search from '../assets/icons/dashboard-search.svg';
import reports from '../assets/icons/dashboard-reports.svg';
import alert from '../assets/icons/dashboard-alert.svg';
import poweredByFinkraft from '../assets/images/powered-by-finkraft-dark-text.svg';

const Wrapper = styled(FlexColumnContainer)`
  width: 100vw;
  min-height: calc(100vh - 260px);
  background: #f2f2f2;
`;

const Container = styled(FlexColumnContainer)`
  width: 1000px;
  height: auto;
  margin-top: 40px;
  margin: 40px auto 100px auto;
  position: relative;

  & > ${FlexRowContainer} {
    align-items: center;

    & > h4 {
      margin: 0 30px 0 10px;
    }

    & > a {
      font-size: 16px;
      text-decoration-line: underline;
      color: #f5a623;
    }
  }

  & > #poweredByFinkraft {
    position: absolute;
    left: 0;
    bottom: -150px;
  }
`;

const Heading = styled.h1`
  font-family: Nunito;
  font-weight: 600;
  font-size: 36.4px;
  line-height: 50px;

  color: #414141;
`;

const SubHeading = styled.h3`
  margin-bottom: 80px;
  font-weight: normal;

  display: flex;
  flex-direction: row;
  align-items: center;

  /* Seperator styling */
  & > div {
    border-width: 4px;
    margin: 0;
    margin-right: 100px;
    height: 4px;

    &::after {
      border-width: 4px;
      height: 4px;
      top: -4px;
    }
  }
`;

const ActionsRow = styled(FlexRowContainer.withComponent('div'))`
  width: 100%;
  flex-wrap: nowrap;
  margin-bottom: 50px;
  justify-content: space-between;
`;

const ActionsGroup = styled.div`
  flex: 0 1 45%;

  & ${FlexRowContainer} {
    justify-content: space-around;

    & > a {
      text-decoration: none;
    }
  }
`;

const ActionGroupHeading = styled.h4`
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 22px;
`;

const ActionItem = styled(FlexColumnContainer.withComponent('div'))`
  justify-content: center;
  align-items: center;

  & > h4 {
    margin-top: 15px;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    color: #414141;
  }
`;

const Card = styled(FlexContainer.withComponent('div'))`
  width: 130px;
  height: 130px;
  background-color: #fff;

  border-radius: 13px;
  border: 0.65px solid #f4f4f4;
  box-shadow: 0px 2.6px 5.85px rgba(173, 173, 173, 0.300126);
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0px 10.6px 20.85px rgba(173, 173, 173, 0.300126);
  }
`;

export const UserDashboardContainer = (props) => {
  return (
    <>
      <Wrapper>
        <Container>
          <Heading>Hi Anit</Heading>
          <SubHeading>
            <Seperator />
            Welcome to your workspace
          </SubHeading>
          <ActionsRow>
            <ActionsGroup>
              <ActionGroupHeading>Corporate Tools</ActionGroupHeading>
              <FlexRowContainer>
                <Link>
                  <ActionItem>
                    <Card>
                      <img src={upload} alt="" />
                    </Card>
                    <h4>Upload</h4>
                  </ActionItem>
                </Link>
                <Link>
                  <ActionItem>
                    <Card>
                      <img src={dashboard} alt="" />
                    </Card>
                    <h4>Dashboard</h4>
                  </ActionItem>
                </Link>
                <Link>
                  <ActionItem>
                    <Card>
                      <img src={graph} alt="" />
                    </Card>
                    <h4>Add/Edit GST</h4>
                  </ActionItem>
                </Link>
              </FlexRowContainer>
            </ActionsGroup>
            <ActionsGroup>
              <ActionGroupHeading>Your Tools</ActionGroupHeading>
              <FlexRowContainer>
                <Link>
                  <ActionItem>
                    <Card>
                      <img src={search} alt="" />
                    </Card>
                    <h4>Search/Download</h4>
                  </ActionItem>
                </Link>
                <Link>
                  <ActionItem>
                    <Card>
                      <img src={reports} alt="" />
                    </Card>
                    <h4>Reports</h4>
                  </ActionItem>
                </Link>
                <Link>
                  <ActionItem>
                    <Card>
                      <img src={reports} alt="" />
                    </Card>
                    <h4>Manage Teams</h4>
                  </ActionItem>
                </Link>
              </FlexRowContainer>
            </ActionsGroup>
          </ActionsRow>
          <FlexRowContainer>
            <img src={alert} alt="" />
            <h4>Your profile is incomplete</h4>
            <Link to="/registration">Complete Now</Link>
          </FlexRowContainer>
          <img id="poweredByFinkraft" src={poweredByFinkraft} alt="" />
        </Container>
      </Wrapper>
    </>
  );
};
