import React from 'react';
import styled from 'styled-components/macro';

const LoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'rgba(0, 0, 0, 0.75)'};
  z-index: 1000;

  .loader-block-container {
    position: relative;
    height: 150px;
    width: 200px;
  }

  .loader-block {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px;
    background-color: #7f2049 !important;
    position: absolute;
  }

  .block-1 {
    animation: block1 2s infinite;
  }

  .block-2 {
    animation: block2 2s infinite;
  }

  .block-3 {
    animation: block3 2s infinite;
  }

  .block-4 {
    animation: block4 2s infinite;
  }

  .block-5 {
    animation: block5 2s infinite;
  }

  @keyframes block1 {
    0% {
      top: 0;
      left: 0;
    }
    12.5% {
      top: 50px;
      left: 0px;
    }
    87.5% {
      top: 50px;
      left: 0px;
    }
    100% {
      top: 0px;
      left: 0;
    }
  }
  @keyframes block2 {
    0% {
      top: 50px;
      left: 0;
    }
    12.5% {
      top: 100px;
      left: 0px;
    }
    25% {
      top: 100px;
      left: 50px;
    }
    37.5% {
      top: 50px;
      left: 50px;
    }
    87.5% {
      top: 50px;
      left: 50px;
    }
    100% {
      top: 50px;
      left: 0px;
    }
  }
  @keyframes block3 {
    0% {
      top: 50px;
      left: 50px;
    }
    25% {
      top: 50px;
      left: 50px;
    }
    37.5% {
      top: 0px;
      left: 50px;
    }
    50% {
      top: 0px;
      left: 100px;
    }
    62.5% {
      top: 50px;
      left: 100px;
    }
    87.5% {
      top: 50px;
      left: 100px;
    }
    100% {
      top: 50px;
      left: 50px;
    }
  }
  @keyframes block4 {
    0% {
      top: 50px;
      left: 100px;
    }
    50% {
      top: 50px;
      left: 100px;
    }
    62.5% {
      top: 100px;
      left: 100px;
    }
    75% {
      top: 100px;
      left: 150px;
    }
    87.5% {
      top: 50px;
      left: 150px;
    }
    100% {
      top: 50px;
      left: 100px;
    }
  }
  @keyframes block5 {
    0% {
      top: 50px;
      left: 150px;
    }
    75% {
      top: 50px;
      left: 150px;
    }
    87.5% {
      top: 0;
      left: 150px;
    }
    100% {
      top: 50px;
      left: 150px;
    }
  }
`;

export const Loader = ({ backgroundColor = '' }) => (
  <LoaderContainer backgroundColor={backgroundColor}>
    <div className="loader-block-container">
      <div className="loader-block block-1"></div>
      <div className="loader-block block-2"></div>
      <div className="loader-block block-3"></div>
      <div className="loader-block block-4"></div>
      <div className="loader-block block-5"></div>
    </div>
  </LoaderContainer>
);
