import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';

import {
  LandingContainer,
  CallbackContainer,
  InvoicesContainer,
  RegistrationContainer,
  ContactUsContainer,
  UserDashboardContainer,
} from './containers';

import {
  Intercom,
  Navbar,
  Footer,
  LoginModal,
  Loader,
  ProtectedRoute,
  GoogleAnalytics,
} from './components';
import { trySilentLogin } from './actions';

const App = () => {
  const showLoader = useSelector((state) => state.showLoader);
  const showLoginModal = useSelector((state) => state.auth.showLoginModal);
  const { isUserLoggedIn, didSilentLogin, emailId } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    dispatch(trySilentLogin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isUserLoggedIn && didSilentLogin) {
      history.push(`/registration?email=${emailId}`);
    }
  }, [isUserLoggedIn, history, didSilentLogin, emailId]);

  return (
    <>
      <Intercom />
      <GoogleAnalytics />
      <Navbar />
      <Switch>
        <Route exact path="/" component={LandingContainer} />
        <Route exact path="/callback" component={CallbackContainer} />
        <Route exact path="/contact-us" component={ContactUsContainer} />
        <ProtectedRoute exact path="/invoices" component={InvoicesContainer} />
        <Route path="/registration" component={RegistrationContainer} />
        <ProtectedRoute
          exact
          path="/user/dashboard"
          component={UserDashboardContainer}
        />
        <Route path="*" component={LandingContainer} />
      </Switch>
      <Footer />
      {showLoginModal && <LoginModal />}
      {showLoader && <Loader backgroundColor="#fff" />}
    </>
  );
};

export default App;
