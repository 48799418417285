const {
  protocol, // retrieve http or https
  hostname, // retrieve domain name eg:- qatar.finkraft.ai
  host, // retrieve domain name along with port number eg:- localhost:8000
} = window.location;

const prodConfig = {
  apiBaseUrl: 'https://qatar-apis.finkraft.ai/api/v1',
  auth0: {
    domain: 'shopelect.auth0.com',
    clientId: 'Ppfj1JyyG8smHdYi2R1gPqSSCALD3F0L',
    redirectUri: `${protocol}//${hostname}/callback`,
    audience: 'https://shopelect.auth0.com/api/v2/',
    responseType: 'token',
    returnTo: `${protocol}//${hostname}`,
  },
  typeFormUrls: {
    companyDetails: (emailId) =>
      `https://qatar-finkraft.typeform.com/to/wuufJnPt?emailid=${emailId}&typeofform=companyDetails`,
    tmcDetails: (emailId) =>
      `https://qatar-finkraft.typeform.com/to/zeKGSqck?emailid=${emailId}&typeofform=tmcDetails`,
    taxContact: (emailId) =>
      `https://qatar-finkraft.typeform.com/to/BbwwNZjm?emailid=${emailId}&typeofform=taxContact`,
    travelContact: (emailId) =>
      `https://qatar-finkraft.typeform.com/to/dtNnF5t0?emailid=${emailId}&typeofform=travelContact`,
    addNewUser: (emailId) =>
      `https://qatar-finkraft.typeform.com/to/s2yItEBg?emailid=${emailId}&typeofform=addMoreUser`,
    addNewPan: (emailId) =>
      `https://qatar-finkraft.typeform.com/to/K5Y6pFA9?emailid=${emailId}&typeofform=addMorePan`,
  },
  googleAnalyticsTrackingId: 'UA-179795518-2',
};

const devConfig = {
  apiBaseUrl: 'https://staging-qa-apis.finkraft.ai/api/v1',
  auth0: {
    domain: 'shopelect.auth0.com',
    clientId: 'Ppfj1JyyG8smHdYi2R1gPqSSCALD3F0L',
    redirectUri: `${protocol}//${host}/callback`,
    audience: 'https://shopelect.auth0.com/api/v2/',
    responseType: 'token',
    returnTo: `${protocol}//${host}`,
  },
  typeFormUrls: {
    companyDetails: (emailId) =>
      `https://qatar-finkraft.typeform.com/to/oCeW09fK?emailid=${emailId}&typeofform=companyDetails`,
    tmcDetails: (emailId) =>
      `https://qatar-finkraft.typeform.com/to/DBwJIhth?emailid=${emailId}&typeofform=tmcDetails`,
    taxContact: (emailId) =>
      `https://qatar-finkraft.typeform.com/to/fEHYwZKK?emailid=${emailId}&typeofform=taxContact`,
    travelContact: (emailId) =>
      `https://qatar-finkraft.typeform.com/to/TIQM7ScO?emailid=${emailId}&typeofform=travelContact`,
    addNewUser: (emailId) =>
      `https://qatar-finkraft.typeform.com/to/FqMtaGch?emailid=${emailId}&typeofform=addMoreUser`,
    addNewPan: (emailId) =>
      `https://qatar-finkraft.typeform.com/to/rQe5b6O0?emailid=${emailId}&typeofform=addMorePan`,
  },
  googleAnalyticsTrackingId: 'UA-179795518-1',
};

export const config =
  process.env.REACT_APP_ENV === 'production' ? prodConfig : devConfig;
