import React from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import ReactGA from 'react-ga';

import { hideLoginModal, showLoader, hideLoader } from '../actions';

import { FlexColumnContainer, ThemedButton } from './Themed';
import { LoginInput } from './LoginInput';

import { isEmailValid, isPhoneNumberValid } from '../utils';
import { Auth } from '../services';

import emailIcon from '../assets/icons/email.svg';
import backArrowIcon from '../assets/icons/back-arrow.svg';

Modal.setAppElement('body');

const auth = new Auth();

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
  },
  content: {
    position: 'absolute',
    top: '107px',
    right: '134px',
    left: 'auto',
    bottom: 'auto',
    width: '285px',
    minHeight: '282px',
    maxHeight: '363px',
    backgroundColor: '#fff',
    borderRadius: '13px',
    zIndex: 150,
  },
};

const ModalContainer = styled(FlexColumnContainer.withComponent('div'))`
  justify-content: space-evenly;
  align-items: flex-start;
  height: 282px;

  h3 {
    font-size: 24px;
  }

  & > p {
    font-size: 14px;
    line-height: 18px;

    & > span {
      color: #0fb2e4;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const GetOtpButton = styled(ThemedButton)`
  width: 227px;
  height: 42px;
  display: block;
  font-size: 14px;
  line-height: 26px;
  margin: 0 auto;
`;

const LoginButton = styled(GetOtpButton)``;

const ConnectionTypeButton = styled(GetOtpButton)``;

const LoginVia = styled.p`
  margin: 0 auto;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid black;
  }
`;

export const LoginModal = () => {
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [emailValid, setIsEmailValid] = React.useState(false);
  const [phoneNumberValid, setIsPhoneNumberValid] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [otp, setOtp] = React.useState('');
  const [loginError, setLoginError] = React.useState('');
  const [otpSent, setOtpSent] = React.useState(false);
  const [loginVia, setLoginVia] = React.useState('');

  const { showLoginModal } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    const { email } = queryString.parse(location.search);
    if (email) {
      setLoginVia('email');
      setEmail(email);
      setIsEmailValid(true);
    }
  }, [location]);

  const validateEmail = async (value) => {
    const { result, error } = await isEmailValid(value);
    if (result) {
      setIsEmailValid(true);
      setErrorMsg('');
    } else {
      setIsEmailValid(false);
      setErrorMsg(error.message);
    }
  };

  const validatePhoneNumber = async (value) => {
    const { result, error } = await isPhoneNumberValid(value);
    if (result) {
      setIsPhoneNumberValid(true);
      setErrorMsg('');
    } else {
      setIsPhoneNumberValid(false);
      setErrorMsg(error.message);
    }
  };

  const onGetOtpClick = async () => {
    if (loginVia === 'email') {
      validateEmail(email);
      if (emailValid) {
        try {
          const result = await auth.emailLoginStart({ email });
          if (result) {
            setOtpSent(true);
          }
        } catch (error) {
          if (error.statusCode === 400) {
            setErrorMsg('Please register before logging in');
          } else {
            alert('Something went wrong!');
          }
        }
      }
    } else {
      validatePhoneNumber(phoneNumber);
      if (phoneNumberValid) {
        try {
          const result = await auth.smsLoginStart({ phoneNumber });
          if (result) {
            setOtpSent(true);
          }
        } catch (error) {
          if (error.statusCode === 400) {
            setErrorMsg('Please register before logging in');
          } else {
            alert('Something went wrong!');
          }
        }
      }
    }
  };

  const onLoginClick = async () => {
    try {
      dispatch(showLoader());
      let result;
      if (loginVia === 'email') {
        result = await auth.emailLoginVerify({
          email,
          verificationCode: otp,
        });
      } else {
        result = await auth.smsLoginVerify({
          phoneNumber,
          verificationCode: otp,
        });
      }
      if (result) {
        ReactGA.event({
          category: 'Registration',
          action: 'Sign-in_success',
        });
        setLoginError('');
      }
    } catch (error) {
      dispatch(hideLoader());
      if (error.statusCode === 400) {
        setLoginError('Please register before logging in');
      } else {
        setLoginError('Something went wrong');
      }
    }
  };

  return (
    <Modal
      isOpen={showLoginModal}
      contentLabel="Login Modal"
      style={modalStyles}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => dispatch(hideLoginModal())}
    >
      <ModalContainer>
        <h3>{!loginVia ? 'Login Via:' : 'Login'}</h3>
        {loginVia && (
          <p style={{ color: 'green' }}>
            {otpSent
              ? `${
                  loginVia === 'email'
                    ? 'OTP has been sent to your email'
                    : 'OTP has been sent to your phone'
                }`
              : ''}
          </p>
        )}
        {loginVia ? (
          <>
            {loginVia === 'email' ? (
              <LoginInput
                icon={emailIcon}
                isIconVisible={true}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => validateEmail(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onGetOtpClick();
                  }
                }}
                placeholder="Enter Email ID"
                errorMsg={errorMsg}
              />
            ) : (
              <LoginInput
                icon={emailIcon}
                isIconVisible={false}
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                onBlur={(e) => validatePhoneNumber(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onGetOtpClick();
                  }
                }}
                placeholder="Enter Phone Number"
                errorMsg={errorMsg}
              />
            )}
            {(emailValid || phoneNumberValid) && otpSent && (
              <LoginInput
                icon={emailIcon}
                isIconVisible={false}
                type="password"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onLoginClick();
                  }
                }}
                placeholder="Enter OTP"
                errorMsg={loginError}
              />
            )}
            {otpSent ? (
              <LoginButton onClick={onLoginClick}>Login</LoginButton>
            ) : (
              <GetOtpButton onClick={onGetOtpClick}>
                Get OTP via{' '}
                <span style={{ textTransform: 'capitalize' }}>{loginVia}</span>
              </GetOtpButton>
            )}
            <LoginVia
              onClick={() => {
                setOtp('');
                setErrorMsg('');
                setOtpSent(false);
                setLoginError('');
                if (loginVia === 'email') {
                  setEmail('');
                  setIsEmailValid(false);
                  setLoginVia('sms');
                } else {
                  setPhoneNumber('');
                  setIsPhoneNumberValid(false);
                  setLoginVia('email');
                }
              }}
            >
              <span>
                <img src={backArrowIcon} alt="" />
                &nbsp;&nbsp;
              </span>
              {loginVia === 'email'
                ? 'Login via phone number'
                : 'Login via email'}
            </LoginVia>
          </>
        ) : (
          <>
            <ConnectionTypeButton onClick={() => setLoginVia('email')}>
              Email Address
            </ConnectionTypeButton>
            <ConnectionTypeButton onClick={() => setLoginVia('sms')}>
              Phone Number
            </ConnectionTypeButton>
          </>
        )}
      </ModalContainer>
    </Modal>
  );
};
