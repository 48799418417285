import React from 'react';
import styled from 'styled-components/macro';

import { FlexColumnContainer } from '../Themed';
import plusIcon from '../../assets/icons/plus-inside-circle.svg';

const Card = styled(FlexColumnContainer.withComponent('div'))`
  width: 160px;
  height: 220px;
  background-color: #fff;
  border: ${(props) =>
    props.isSelected && !props.isCompleted
      ? '1px solid #df5590'
      : '0.5px solid #f4f4f4'};
  box-shadow: 0px 2.3px 5px rgba(173, 173, 173, 0.3);
  border-radius: 5.7px;

  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: border 0.1s ease-in;
`;

const Thumbnail = styled.img`
  width: 80px;
  height: 80px;
  margin-top: 20px;
`;

const CardTitle = styled.h1`
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
`;

const CardDescription = styled.p`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 130px;
  color: ${(props) => (props.isCompleted ? 'black' : 'red')};
  font-weight: 600;
`;

const AddMore = styled.p`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 150px;
  color: #444444;
  font-weight: 600;
  margin-top: 10px;
  text-decoration: underline dashed;

  & > img {
    margin-right: 5px;
  }
`;

export const FormTypeCard = React.memo(
  ({
    isSelected,
    thumbnail,
    cardTitle,
    isCompleted,
    onCardClick,
    canAddMore = false,
  }) => {
    return (
      <Card
        isCompleted={isCompleted}
        isSelected={isSelected}
        onClick={() =>
          isCompleted ? {} : onCardClick(cardTitle.toLowerCase())
        }
      >
        <Thumbnail src={thumbnail} alt="card asset" />
        <CardTitle>
          {cardTitle.toLowerCase() === 'tmc details'
            ? 'Travel Agent Details'
            : cardTitle}
        </CardTitle>
        <CardDescription isCompleted={isCompleted}>
          {isCompleted ? 'Completed' : 'Pending'}
        </CardDescription>
        {canAddMore && (
          <AddMore onClick={() => onCardClick(cardTitle.toLowerCase())}>
            <img src={plusIcon} alt="" />
            Add more {cardTitle}
          </AddMore>
        )}
      </Card>
    );
  }
);

export const DummyFormTypeCard = React.memo(
  ({ isSelected, thumbnail, cardTitle, cardDesc = '', onCardClick }) => {
    return (
      <Card
        isSelected={isSelected}
        onClick={() => onCardClick(cardTitle.toLowerCase())}
      >
        <Thumbnail src={thumbnail} alt="card asset" />
        <CardTitle>{cardTitle}</CardTitle>
        <CardDescription>{cardDesc}</CardDescription>
      </Card>
    );
  }
);
