import React from 'react';
import { Switch, Route } from 'react-router-dom';

// import BusinessTypeContainer from './BusinessTypeContainer';
// import RegistrationDetailsContainer from './RegistrationDetailsContainer';
import RegistrationProgressContainer from './RegistrationProgressContainer';

export const RegistrationContainer = (props) => {
  const basePath = props.match.path;

  return (
    <>
      <Switch>
        {/* <Route exact path={`${basePath}`} component={BusinessTypeContainer} /> */}
        <Route
          exact
          path={`${basePath}`}
          component={RegistrationProgressContainer}
        />
        {/* <Route
          exact
          path={`${basePath}/details`}
          component={RegistrationDetailsContainer}
        /> */}
      </Switch>
    </>
  );
};
