import {
  SHOW_LOGIN_MODAL,
  HIDE_LOGIN_MODAL,
  SET_LOGGEDIN_USER,
  LOGOUT_USER,
} from '../actions';

const initialState = {
  showLoginModal: false,
  isUserLoggedIn: false,
  didSilentLogin: false,
  accessToken: '',
  emailId: '',
  auth0Result: {},
  auth0User: {},
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOGIN_MODAL:
      return { ...state, showLoginModal: true };

    case HIDE_LOGIN_MODAL:
      return { ...state, showLoginModal: false };

    case SET_LOGGEDIN_USER:
      return Object.assign({}, state, {
        isUserLoggedIn: true,
        didSilentLogin: action.payload.didSilentLogin,
        accessToken: action.payload.accessToken,
        emailId: action.payload.emailId,
        auth0Result: action.payload.auth0Result,
        auth0User: action.payload.auth0User,
      });

    case LOGOUT_USER:
      return { ...state, ...initialState };

    default:
      return state;
  }
};
