import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { FlexRowContainer } from '../Themed';

import backArrow from '../../assets/icons/back-arrow.svg';

const Header = styled(FlexRowContainer.withComponent('header'))`
  width: 740px;
  height: 45px;

  justify-content: space-evenly;
  align-items: center;

  background: #ffffff;
  box-shadow: 0.555974px 1.11195px 4.44779px rgba(173, 173, 173, 0.300126);

  & > p {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
  }
`;

const VerticalDivider = styled.div`
  height: 30px;
  width: 1px;
  mix-blend-mode: normal;
  opacity: 0.2;
  border: 0.55px solid #979797;
`;

export const RegistrationDetailsHeader = ({ tabs, currentTabIndex = 1 }) => {
  return (
    <Header>
      <Link to="/registration">
        <img src={backArrow} alt="" />
      </Link>
      <VerticalDivider />
      {tabs.map((tab, i) => (
        <React.Fragment key={i}>
          <p
            style={{
              fontWeight: currentTabIndex === i + 1 ? 600 : 400,
            }}
          >
            {tab}
          </p>
          {tabs.length !== i + 1 && <VerticalDivider />}
        </React.Fragment>
      ))}
    </Header>
  );
};
